<template>
    <div class="history">
        <Popup 
            class="popup" 
            custom-class="gray-header"
            modal-id="history" 
            :on-close="onModalClose" 
            size="lg" 
            :toggle-status="toggleStatus"
        >
            <template v-slot:header> {{ labels.history }} </template>
            <template v-slot:body>
                <CollapsibleHistoryList
                    :historyData="historyData"
                    :isSelected="isSelected"
                    :action="deleteHistoryMD"
                />
            </template>
            <template v-slot:footer>
                <Button
                    variant="btn-outline-purple"
                    :label="labels.cancel"
                    :action="onModalClose"
                />
                <Button
                    variant="btn-purple"
                    :label="labels.display_selected_version"
                    :action="displaySelectedVersion"
                />
            </template>
        </Popup>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import Popup from "@/components/Popups/Popup";
import Button from "@/components/Buttons/Button";
import CollapsibleHistoryList from "@/components/List/CollapsibleHistoryList.vue";
import { mapGetters } from "vuex";

export default {
    name: 'History',
    components: {
        Popup,
        Button,
        CollapsibleHistoryList,
    },
    data() {
        return {
            toggleStatus: false,
            // history: [{_id: '2132423qewqd', content: '', date: '22.10.2022'}, {_id: 'desad231', content: '', date: '04.11.2022'}],
            historyData: [
                // {   
                //     id: 1,
                //     change: 'XX.XX.XXXX',
                //     last_update: 'XX.XX.XXXX',
                //     history: {1: '1X.XX.XXXX XX.XX', 2: '2X.XX.XXXX XX.XX'},
                //     expanded: true,
                //     collapsed: true,
                //     value: null,
                //     action: () => [this.deleteHistoryMD()],
                // },
                // {   
                //     id: 2,
                //     change: 'XX.XX.XXXX',
                //     last_update: 'XX.XX.XXXX',
                //     history: {3: '3X.XX.XXXX XX.XX', 4: '4X.XX.XXXX XX.XX'},
                //     expanded: false,
                //     collapsed: false,
                //     value: null,
                //     action: () => [this.deleteHistoryMD()],
                // }
            ]
        }
    },
    methods: {
        ...mapActions( 'notification', {
            addNotification: 'addNotification',
        } ),
        ...mapActions('_module', {
			getContentHistory: 'getContentHistory',
            getSingleHistory: 'getSingleHistory',
            deleteHistory: 'deleteHistory',
		}),
        onModalClose() {
            this.$router.push( { name: 'module-editor' } );
            this.toggleStatus = false;
            let element = document.querySelector('.modal-backdrop');
            if ( element ) {
                element.remove();
                document.querySelector('body').classList.remove('modal-open');
            }
        },
        loadHistory() {
            this.getContentHistory(this.moduleId).then( res => {
                this.historyData = res.data;
                console.log('History response:', res.data);
            })
        },
        displaySelectedVersion() {
            this.getSingleHistory(this.moduleId) // need add right data
                .then( res  => {
                    if (res.success) {
                        console.log(res);
                        this.onModalClose();
                        this.addNotification( { variant: 'success', msg: res.msg, labels: this.labels } );
                    } else if (res.error) {
                        this.addNotification({ variant: "danger", msg: res.error, labels: this.labels });
                    }
                })
                .catch((err) => console.log(err));
        },
        deleteHistoryMD(id) {
            this.deleteHistory({ id:id }) // need add right data
                .then( res  => {
                    if (res.success) {
                        console.log(res);
                        this.onModalClose();
                        this.addNotification( { variant: 'success', msg: res.msg, labels: this.labels } );
                    } else if (res.error) {
                        this.addNotification({ variant: "danger", msg: res.error, labels: this.labels });
                    }
                })
                .catch((err) => console.log(err));
        },
        isSelected(id) {
            console.log("Checked " + id);
        }
    },
    mounted() {
        this.toggleStatus = true;
        this.loadHistory();
    },
    beforeDestroy() {
        this.toggleStatus = false;
    },
    computed: {
        ...mapGetters('helper', ['labels']),
        moduleId() {
            return this.$route.params.id;
        }
    }
}
</script>

<style lang="scss" scoped></style>