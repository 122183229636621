<template>
  <div class="wrapper">
    <div class="list-wrapper" v-for="(data, index) in historyData" :key="index">
      <div class="list-items">
        <div class="items-title">
          <div class="item-create-date">
            <FormulateInput
              name="data-name"
              class="list-radio-input"
              :options="data.id"
              type="radio"
              @click="isSelected(data._id)"
            />
            <span @click="toggleItem(index)"
              >{{ labels.change }}: {{ normalDate(data.created.date) }}</span
            >
          </div>
          <font-awesome-icon
            :class="{ hide: data.collapsed }"
            class="primary-icon"
            icon="custom-trash"
            @click="action(data._id)"
          />
          <span class="gray-text" @click="toggleItem(index)">
            {{ labels.last_update }}: {{ normalDate(data.lastChanged.date) }}
          </span>
          <font-awesome-icon
            :class="{
              collapsed: !indexesExpanded[index],
              open: indexesExpanded[index],
            }"
            class="primary-icon"
            icon="chevron-up"
            data-toggle="collapse"
            :data-target="`#items-list-${data.id}`"
            @click="toggleItem(index)"
          />
        </div>
        <div
          :class="['collapse', { show: indexesExpanded[index] }]"
          :id="`items-list-${data.id}`"
        >
          <div class="item">
            <ul>
              <li v-for="(h, id) in data.history" :key="id">
                <FormulateInput
                  :label="normalDate(h.date)"
                  name="data-name"
                  type="radio"
                  @click="isSelected(h._id)"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { customTrash } from "@/assets/icons";
library.add(faChevronUp, customTrash);
import moment from "moment";

export default {
  name: "CollapsibleHistoryList",
  data() {
    return {
      labels: {
        change: "Created",
        last_update: "Last update",
      },
      // disabledCheckBoxes: false,
      // disabledList: false,
      indexesExpanded: {},
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    historyData: {
      type: Array,
      default: () => [],
    },
    expanded: {
      type: Boolean,
      default: true,
    },
    isSelected: {
      type: Function,
      default: () => {},
    },
    action: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    toggleItem(index) {
      if (!this.indexesExpanded[`${index}`])
        this.$set(this.indexesExpanded, `${index}`, true);
      else this.$set(this.indexesExpanded, `${index}`, false);
    },
    normalDate(date) {
      return moment(date).format("DD.MM.YYYY - hh:mm");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.wrapper {
  .list-wrapper {
    box-sizing: border-box;
    border: 1px solid $light_gray;

    .list-items {
      margin-bottom: 30px;
      border-radius: 5px;
      background: $white;
      overflow: hidden;
      padding: 0;
      margin: 0;
      font-size: 18px;
      color: $black;
      font-weight: 400;
      line-height: 1.5;
      text-align: left;
      .item {
        li {
          padding: 15px 25px;
        }
      }
      .item-create-date {
        display: flex;
        gap: 10px;
      }
      .list-radio-input {
        margin: 0;
        display: flex;
      }
      svg {
        color: $darkgray;
      }

      .items-title {
        padding: 15px 25px;
        color: $black;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        word-break: break-word;
        background-color: transparent;
        cursor: pointer;

        .primary-icon {
          width: 20px;
          height: 20px;
        }

        .collapsed {
          transform: rotate(-180deg);
          transition: all 0.4s ease;
        }

        .open {
          transition: all 0.4s ease;
        }

        .gray-text {
          color: $light_text;
        }
      }

      ul {
        padding: 0;
        margin: 0;
        li {
          font-size: 15px;
          list-style-type: none;
          border-top: 1px solid $light_gray;
          a {
            padding: 15px 25px 15px 25px;
            background: $white;
            font-weight: 500;
            color: #414141;
            position: relative;
            text-decoration: none;

            &:hover {
              background: $navy;
              color: $white;
              text-decoration: none;
              transition: all 0.3s ease;

              svg {
                color: $white;
              }
            }
          }
        }
      }

      .hide {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
  h3 {
    display: none;
    font-family: "Oswald", sans-serif;
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    @media screen and (max-width: 992px) {
      display: block !important;
    }
  }
}

::v-deep {
  .formulate-input {
    .formulate-input-group-item {
      margin-bottom: 0;
    }
    .formulate-input-element {
      max-width: 100%;
      .formulate-input-wrapper {
        padding: 15px 25px;
        border-top: 1px solid $light_gray;
      }
      .formulate-input-element-decorator {
        margin-bottom: 0;
        cursor: pointer;
      }
      .formulate-input-label {
        flex: 1;
        cursor: pointer;
      }
    }
  }
}
</style>
